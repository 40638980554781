<template lang="pug">
#EinsteinClock__container
  #EinsteinClock__picture
    .EinsteinPicture__overlay
  #EinsteinClock
    .ellipses-container
      .ellipses.ellipses__outer--thin
        .ellipses.ellipses__orbit
      .ellipses.ellipses__outer--thick
    .title-top Einstein
    .time-is-relative Time <br> is Relative
    .vertical-information 14 March 1879 – 18 April 1955
    .top-right-text The clock that changed <br> the meaning <br> of time.
    .scroller
      .page-title
      .timeline
        .timeline__unit
        .timeline__unit.timeline__unit--active
        .timeline__unit
    #clock
      #minutes-separator
        div :
        div :
      #circle__inner
        #analog_dots
        .hand-container#seconds-center
          .second-hand
        .hand-container#minutes-center
          .minute-hand
        .hand-container#hours-center
          .hour-hand
        .center-dot
      #timeMarker
      #month_names
      #month_days
      #week_days
      #hours
      #minutes
      #seconds
</template>

<script>
import { onDeactivated, onMounted, onUnmounted, onUpdated } from 'vue';

export default {
  name: 'Clock',
  setup () {
    function draw () {
      //  Days numbers
      for (let i = 31; i > 0; i--) {
        let value = (i < 10) ? '0' + i : i;
        let container = document.getElementById('month_days');
        let div = document.createElement('div');
        div.id = 'month_day_' + i;
        div.innerText = value;
        if (container) container.append(div);
      }

      //  Days names
      let daysNamesArray = ['Sun', 'Sat', 'Fri', 'Thu', 'Wed', 'Tue', 'Mon'];
      for (const index in daysNamesArray) {
        let name = daysNamesArray[index];
        let container = document.getElementById('week_days');
        let div = document.createElement('div');
        div.id = 'week_day_' + Math.abs(index - 7);
        div.innerText = name;
        if (container) container.append(div);
      }

      //  Month names
      let theMonthNames = ['Dec', 'Nov', 'Oct', 'Sep', 'Agu', 'Jul', 'Jun', 'May', 'Apr', 'Mar', 'Fev', 'Jan'];
      for (const index in theMonthNames) {
        let name = theMonthNames[index];
        let container = document.getElementById('month_names');
        let div = document.createElement('div');
        div.id = 'month_name_' + Math.abs(index - 11);
        div.innerText = name;
        if (container) container.append(div);
      }

      //  Hours
      for (let i = 23; i >= 0; i--) {
        let value = (i < 10) ? '0' + i : i;
        let container = document.getElementById('hours');
        let div = document.createElement('div');
        div.id = 'aHour_' + i;
        div.innerText = value;
        if (container) container.append(div);
      }

      //  Minutes
      for (let i = 59; i >= 0; i--) {
        let value = (i < 10) ? '0' + i : i;
        let container = document.getElementById('minutes');
        let div = document.createElement('div');
        div.id = 'aMin_' + i;
        div.innerText = value;
        if (container) container.append(div);
      }

      //  Seconds
      for (let i = 59; i >= 0; i--) {
        let value = (i < 10) ? '0' + i : i;
        let container = document.getElementById('seconds');
        let div = document.createElement('div');
        div.id = 'aSec_' + i;
        div.innerText = value;
        if (container) container.append(div);
      }

      //  Center analog dots
      let containerInnerCircle = document.getElementById('analog_dots');
      if (containerInnerCircle) {
        for (let i = 0; i < 12; i++) {
          let div = document.createElement('div');
          div.className = 'analog_dot';
          containerInnerCircle.append(div);
        }
      }
    }


    function setInPosition () {
      let deg_week_days = 360 / 7;
      let deg_month_days = 360 / 31;
      let deg_month_names = 360 / 12;
      let deg_hours = 360 / 24;
      let deg_hours_analog = 360 / 12;
      let deg_minutes = 360 / 60;
      let deg_seconds = 360 / 60;

      let month_days = document.getElementById('month_days');
      let month_names = document.getElementById('month_names');
      let week_days = document.getElementById('week_days');
      let hours = document.getElementById('hours');
      let minutes = document.getElementById('minutes');
      let seconds = document.getElementById('seconds');
      let analog_dots = document.getElementById('analog_dots');

      if (week_days && month_days && month_names && hours && minutes && seconds && analog_dots) {
        [...week_days.children].forEach((item, index) => {
          item.style.transform = `rotateZ(${deg_week_days * index}deg) translateX(85px)`;
        });

        [...month_days.children].forEach((item, index) => {
          item.style.transform = `rotateZ(${deg_month_days * index}deg) translateX(118px)`;
        });

        [...month_names.children].forEach((item, index) => {
          item.style.transform = `rotateZ(${deg_month_names * (index + 1)}deg) translateX(145px)`;
        });

        [...hours.children].forEach((item, index) => {
          item.style.transform = `rotateZ(${deg_hours * (index + 1)}deg) translateX(180px)`;
        });

        [...minutes.children].forEach((item, index) => {
          item.style.transform = `rotateZ(${deg_minutes * (index + 1)}deg) translateX(205px)`;
        });

        [...seconds.children].forEach((item, index) => {
          item.style.transform = `rotateZ(${deg_seconds * (index + 1)}deg) translateX(230px)`;
        });

        [...analog_dots.children].forEach((item, index) => {
          item.style.transform = `rotateZ(${deg_hours_analog * (index + 1)}deg) translateX(60px)`;
        });
      }
    }


    const degrees = {
      month_day: null,
      month_name: null,
      week_day: null,
      hours: null,
      hours_analog: null,
      minutes: null,
      seconds: null,
    };


    const current = {
      month_day: null,
      month_name: null,
      week_day: null,
      hours: null,
      minutes: null,
      seconds: null,
    };


    function updateRotation () {
      let week_days = document.getElementById('week_days');
      if (week_days) week_days.style.transform = `rotateZ(${degrees.week_day}deg)`;

      let month_names = document.getElementById('month_names');
      if (month_names) month_names.style.transform = `rotateZ(${degrees.month_name}deg)`;

      let month_days = document.getElementById('month_days');
      if (month_days) month_days.style.transform = `rotateZ(${degrees.month_day}deg)`;

      let hours = document.getElementById('hours');
      if (hours) hours.style.transform = `rotateZ(${degrees.hours}deg)`;

      let hours_center = document.getElementById('hours-center');
      if (hours_center) hours_center.style.transform = `rotateZ(${degrees.hours_analog}deg)`;

      let minutes = document.getElementById('minutes');
      if (minutes) minutes.style.transform = `rotateZ(${degrees.minutes}deg)`;

      let seconds = document.getElementById('seconds');
      if (seconds) seconds.style.transform = `rotateZ(${degrees.seconds}deg)`;

      let minutes_center = document.getElementById('minutes-center');
      if (minutes_center) minutes_center.style.transform = `rotateZ(${degrees.minutes}deg)`;

      let seconds_center = document.getElementById('seconds-center');
      if (seconds_center) seconds_center.style.transform = `rotateZ(${degrees.seconds}deg)`;
    }

    //
    //
    //  CLOCK
    function updateCurrentSecond (second) {
      let activeValue = document.querySelector('#seconds .activeValue');
      if (activeValue) activeValue.classList.remove('activeValue');
      let element = document.getElementById('aSec_' + second);
      if (element) element.classList.add('activeValue');
    }

    function updateCurrentMinute (minute) {
      let activeValue = document.querySelector('#minutes .activeValue');
      if (activeValue) activeValue.classList.remove('activeValue');
      let element = document.getElementById('aMin_' + minute);
      if (element) element.classList.add('activeValue');
    }

    function updateCurrentHours (hour) {
      let activeValue = document.querySelector('#hours .activeValue');
      if (activeValue) activeValue.classList.remove('activeValue');
      let element = document.getElementById('aHour_' + hour);
      if (element) element.classList.add('activeValue');
    }

    function updateCurrentMonthName (month_name) {
      let activeValue = document.querySelector('#month_names .activeValue');
      if (activeValue) activeValue.classList.remove('activeValue');
      let element = document.getElementById('month_name_' + month_name);
      if (element) element.classList.add('activeValue');
    }

    function updateCurrentDaysNumber (month_day) {
      let activeValue = document.querySelector('#month_days .activeValue');
      if (activeValue) activeValue.classList.remove('activeValue');
      let element = document.getElementById('month_day_' + month_day);
      if (element) element.classList.add('activeValue');
    }

    function update_week_day (week_day) {
      let activeValue = document.querySelector('#week_days .activeValue');
      if (activeValue) activeValue.classList.remove('activeValue');
      let element = document.getElementById('week_day_' + week_day);
      if (element) element.classList.add('activeValue');
    }


    function validateDaysOfMonth (daysInMonth) {
      if (typeof daysInMonth === 'number' && daysInMonth >= 28 && daysInMonth <= 31) {
        let not_a_day = document.querySelectorAll('#month_days .is-not-a-day');

        [...not_a_day].forEach(item => item.classList.remove('is-not-a-day'));

        for (let i = daysInMonth + 1; i <= 31; i++) {
          let element = document.getElementById('month_day_' + i);
          if (element) element.classList.add('is-not-a-day');
        }
      }
    }


    function clock () {
      if (!document.getElementById('EinsteinClock')) return;

      let date = new Date();
      let year = date.getHours();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      let month_day = date.getDate();
      let month_name = date.getMonth();
      let week_day = date.getDay() || 7;

      let lastDayOfMonth = new Date(year, month_name + 1, 0);
      let daysInMonth = lastDayOfMonth.getDate();

      let degrees_month_day = 360 / 31 * month_day;
      let degrees_month_name = 360 / 12 * month_name;
      let degrees_week_day = 360 / 7 * week_day;
      let degrees_hours = 360 / 24 * hours;
      let degrees_hours_analog = 360 / 12 * hours;
      let degrees_minutes = 360 / 60 * minutes;
      let degrees_seconds = 360 / 60 * seconds;

      if (degrees.seconds === null) {
        current.month_day = month_day;
        current.month_name = month_name;
        current.week_day = week_day;
        current.hours = hours;
        current.minutes = minutes;
        current.seconds = seconds;
        degrees.month_day = degrees_month_day;
        degrees.month_name = degrees_month_name;
        degrees.week_day = degrees_week_day;
        degrees.hours = degrees_hours;
        degrees.hours_analog = degrees_hours_analog;
        degrees.minutes = degrees_minutes;
        degrees.seconds = degrees_seconds;
        validateDaysOfMonth(daysInMonth);
      }

      if (current.month_day !== month_day) {
        current.month_day = month_day;
        degrees.month_day = degrees_month_day;
      }

      if (current.month_name !== month_name) {
        current.month_name = month_name;
        degrees.month_name += 30;
        validateDaysOfMonth(daysInMonth);
      }

      if (current.week_day !== week_day) {
        current.week_day = week_day;
        degrees.week_day += 360 / 7;
      }

      if (current.hours !== hours) {
        current.hours = hours;
        degrees.hours += 15;
        degrees.hours_analog += 30;
      }

      if (current.minutes !== minutes) {
        current.minutes = minutes;
        degrees.minutes += 6;
      }

      if (current.seconds !== seconds) {
        current.seconds = seconds;
        degrees.seconds += 6;
      }

      updateRotation();
      updateCurrentSecond(seconds);
      updateCurrentMinute(minutes);
      updateCurrentHours(hours);
      updateCurrentDaysNumber(month_day);
      updateCurrentMonthName(month_name);
      update_week_day(week_day);

      setTimeout(clock, 1000);
    }


    onMounted(() => {
      draw();
      setInPosition();
      clock();
    });


    onUpdated(() => {
      current.hours = null;
      current.minutes = null;
      current.seconds = null;
      degrees.hours = null;
      degrees.minutes = null;
      degrees.seconds = null;
    });

  },
};
</script>

<style lang="stylus">
$tiber = #09383E
#EinsteinClock__container
  position absolute
  top 70px
  right 70px
  bottom 70px
  left 70px
  min-height 500px
  overflow hidden
  border-radius 20px
  display flex
  box-shadow 0 10px 30px 0 rgba(0, 0, 0, 0.32)
  align-items stretch
  #EinsteinClock__picture
    position relative
    background-image url('../../public/clock/AlbertEinstein.webp')
    background-position center
    background-size cover
    align-self stretch
    width 400px
    flex 1
    color white
    .EinsteinPicture__overlay
      background-image url('../../public/clock/Albert_Einstein_signature_1934.svg')
      background-color rgba(255, 255, 255, 0.4)
      background-repeat no-repeat
      background-position center
      background-size 50%
      filter invert()
      position absolute
      top auto
      left 0
      bottom 0
      right 0
      height 70px
  #EinsteinClock
    background #f2f7f8
    min-width 650px
    max-width 800px
    position relative
    flex 2
    .ellipses-container
      position absolute
      top calc(50% - 265px)
      left calc(50% - 265px)
      width 530px
      height 530px
      border-radius 50%
      z-index 100
      .ellipses
        border-radius 50%
        border-style solid
        position absolute
        top 0
      .ellipses__outer--thin
        width 100%
        height 100%
        border-width 1px
        border-color rgba(9, 56, 62, 0.1)
        animation ellipsesOrbit 40s ease-in-out infinite
        .ellipses__orbit
          width 30px
          height 30px
          border-width 1px
          border-color $tiber
          left 50%
          top -15px
          &:before
            content ''
            width 7px
            height 7px
            border-radius 50%
            display inline-block
            background-color $tiber
            margin 0 auto
            left 0
            right 0
            position absolute
            top 50%
            transform translateY(-50%)
      .ellipses__outer--thick
        width 100%
        height 100%
        border-color $tiber transparent
        border-width 2px
        transform rotate(-45deg)
        animation ellipsesRotate 20s ease-in-out infinite
    .title-top
      position absolute
      top 20px
      left 20px
      font 700 32px / 35px AvertaStd
      letter-spacing 1px
      color $tiber
      text-align left
    .time-is-relative
      position absolute
      bottom 20px
      left 20px
      font 500 20px / 30px Averta, sans-serif;
      letter-spacing 1px
      color $tiber
      text-align left
    .vertical-information
      position absolute
      top 70px
      left 27px
      transform rotateZ(90deg)
      transform-origin 0
      font 300 13px Averta, sans-serif;
      letter-spacing 3px
      color rgb(9, 56, 62)
    .top-right-text
      font 400 16px / 25px Averta, sans-serif;
      position absolute
      text-align right
      top 20px
      right 20px
      width 200px
      height 70px
    .scroller
      position absolute
      bottom 30px
      right 20px
      font 400 12px Averta
      width 80px
      transform translateY(50%)
      overflow hidden
      .page-title
        position absolute
        top 20px
        letter-spacing 2px
      .timeline
        width 1.5rem
        height 9rem
        display inline-block
        float right
        .timeline__unit
          width 100%
          height 0.1rem
          background $tiber
          margin 0 0 30px
          opacity 0.2
          &.timeline__unit--active
            opacity 1
            &:after
              opacity 0.2
          &:after
            content ''
            width 70%
            height 0.1rem
            display block
            position relative
            float right
            background $tiber
            top 1rem
    #clock
      font 400 12px Averta, sans-serif;
      background white
      position absolute
      top calc(50% - 250px)
      left calc(50% - 250px)
      width 500px
      height 500px
      border-radius 50%
      z-index 1
      #minutes-separator
        position absolute
        z-index 100
        width 29px
        height 8px
        right 30px
        top calc(50% - 6px)
        display flex
        align-items center
        justify-content space-between
        animation pulse 1s infinite
        font 700 14px / 0 AvertaStd
      #timeMarker
        position absolute
        top calc(50% - 12px)
        right 0
        width 187px
        height 22px
        background #e6f3f4
        border 1px dashed #9db5b6
        border-radius 10px
      #circle__inner
        position absolute
        top calc(50% - 60px)
        left calc(50% - 60px)
        width 120px
        height 120px
        border-radius 50%
        background #e6f3f4
        border 1px dashed #9db5b6
        #analog_dots
          position absolute
          top 0
          left 0
          bottom 0
          right 0
          .analog_dot
            position absolute
            top calc(50% - 2px)
            left calc(50% - 2px)
            height 4px
            width 4px
            border-radius 50%
            background black
        .center-dot
          position absolute
          top calc(50% - 4px)
          left calc(50% - 4px)
          height 8px
          width 8px
          border-radius 50%
          background $tiber
        .hand-container
          position absolute
          height 100%
          width 4px
          top 0
          left calc(50% - 2px)
          transform-origin center center
          transition transform 100ms linear
          .hour-hand
            position absolute
            top 25px
            left 0
            bottom auto
            right 0
            height 35px
            border-radius 50% 50% 2px 2px
            background $tiber
          .minute-hand
            position absolute
            top 10px
            left 0.5px
            bottom auto
            right 0.5px
            height 50px
            border-radius 50% 50% 0 0
            background $tiber
            opacity 0.5
          .second-hand
            position absolute
            top 0
            left 1px
            bottom auto
            right 1px
            height 70px
            border-radius 50% 50% 0 0
            background red

#seconds, #minutes, #hours, #month_days, #week_days, #month_names
  position absolute
  padding 0
  top 50%
  left 50%
  width 20px
  height 20px
  margin -10px 0 0 -10px
  transform rotateZ(0deg)
  transition all .5s cubic-bezier(0.5, -0.5, 0.500, 1.5)
  color gray
  > div
    position absolute
    width 20px
    height 20px
    line-height 20px
    text-align center
    transform-origin 50%
    transition all .25s linear

.activeValue
  font 600 14px Averta
  line-height 20px
  color black

.is-not-a-day
  color red
  opacity 0.2
  text-decoration line-through

@keyframes pulse
  0%, 50%
    opacity: 1
  51%, 100%
    opacity: 0

@keyframes ellipsesRotate
  0%
    transform: rotate(-45deg);
  100%
    transform: rotate(-405deg);

@keyframes ellipsesOrbit
  0%
    transform: rotate(0);
  100%
    transform: rotate(360deg);

</style>